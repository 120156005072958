























import { Component, Vue, Model, Emit, Prop, Watch } from 'vue-property-decorator'
import { DropDownController, GraduationController } from '@/services/request.service'
import Studies from './Studies.vue'
import GraduationProgress from './GraduationProgress.vue'
import Academic from '@/views/ReportMgmt/AcademicStanding/AcademicDetail.vue'
import Grading from '@/views/common/studentDetail/components/Grading.vue'

@Component({
  components: {
    Studies,
    GraduationProgress,
    Academic,
    Grading,
  },
})
export default class GraduationMenu extends Vue {
  private menuKey = ['studies']

  private studies(): any {
    return this.$refs.studies || null
  }

  private graduationProgress(): any {
    return this.$refs.graduationProgress || null
  }

  private academic(): any {
    return this.$refs.academic || null
  }

  private grading(): any {
    return this.$refs.grading || null
  }

  public refreshData(): void {
    this[this.menuKey[0]]().getData()
    // console.log(this[this.menuKey[0]])
  }
}
