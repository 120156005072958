































import { Component, Vue, Prop, Emit, Model, Watch } from 'vue-property-decorator'
import { DropDownController, GraduationController } from '@/services/request.service'

@Component
export default class ChangeStandardModal extends Vue {
  @Model('change', { type: Boolean }) private readonly visible!: boolean
  @Prop() private readonly realStudentIds!: Array<any>
  @Prop() private readonly currentStandardId!: any

  private standardList: Array<any> = []
  private form: any = {
    graduationSettingId: undefined,
  }
  private changeLoading: boolean = false

  @Watch('visible', { immediate: true })
  private onVisibleChange(value): void {
    if (value) {
      this.getDropDownInfo()
    }
  }

  @Emit('change')
  private closeModal(): boolean {
    return false
  }

  @Emit('cancel')
  private cancel(): boolean {
    this.closeModal()
    return false
  }

  @Emit('confirm')
  private confirm(): boolean {
    this.closeModal()
    return true
  }

  private changeStandard(): any {
    // this.changeVisible = true
    // console.log(record)
    ;(this.$refs['form'] as any).validate(valid => {
      if (valid) {
        this.changeLoading = true
        GraduationController.updateStudentStandard(
          this.realStudentIds,
          this.form.graduationSettingId
        )
          .then(res => {
            this.$message.success(this.$tc('common.saveSuccess'))
            this.confirm()
          })
          .catch(err => console.log(err))
          .finally(() => (this.changeLoading = false))
      } else {
        return false
      }
    })
  }

  private getDropDownInfo(): void {
    GraduationController.graduationList()
      .then(res => {
        this.standardList = res.data
        this.form.graduationSettingId =
          this.currentStandardId || res.data.find(item => item.defaultStandard)?.graduationSettingId
      })
      .catch(err => console.log(err))
  }
}
