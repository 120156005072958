













































































































































































































































































import { Component, Vue, Model, Emit, Prop, Watch } from 'vue-property-decorator'
import {
  DropDownController,
  GraduationController,
  CourseController,
} from '@/services/request.service'
import FlexTooltip from '@/components/FlexTooltip.vue'
import cloneDeep from 'lodash/cloneDeep'
import EventBus from '@/utils/EventBus'
import { round } from 'lodash'

@Component({
  components: {
    // Studies,
    // Progress,
    // Academic,
    // Grading,
    FlexTooltip,
  },
})
export default class Studies extends Vue {
  private schoolCourses: any = []
  private extraCourses: any = []
  private data: any = {}
  private loading: Boolean = false
  private addLoading: Boolean = false
  private extraType: string = 'add'
  private schoolYearList: Array<any> = []
  private subjects: Array<any> = []
  private alternativeSubjects: Array<any> = []
  private courses: Array<any> = []
  private round = round

  private visible: boolean = false

  private form: any = {
    courseName: undefined,
    schoolYearId: undefined,
    scoringType: 'COMPULSORY',
    credit: 0,
    grade: undefined,
    relevancyCourseId: [],
    remark: undefined,
  }

  private get locale(): any {
    return this.$store.state.locale
  }

  private get studentId(): number {
    return parseInt(this.$route.params.studentId, 10)
  }

  private get operationAuths(): any {
    return this.$store.state.operationAuths || []
  }

  private get downColumns(): any {
    return [
      {
        title: this.$t('graduation.course'),
        dataIndex: 'courseName',
        key: 'courseName',
        scopedSlots: { customRender: 'courseName' },
        ellipsis: true,
      },
      {
        title: this.$t('graduation.category'),
        dataIndex: 'subjectName',
        key: 'subjectName',
        filters: cloneDeep(this.subjects),
        onFilter: (value, record) => record.subjectName.indexOf(value) === 0,
        scopedSlots: { customRender: 'commonFlex' },
        width: '17%',
        // ellipsis: true,
      },
      {
        title: this.$t('graduation.courseType'),
        dataIndex: 'courseType',
        key: 'courseType',
        filters: [
          {
            text: '--',
            value: '--',
          },
          {
            text: 'ECP',
            value: 'ECP',
          },
          {
            text: 'CP',
            value: 'CP',
          },
          {
            text: 'EAP',
            value: 'EAP',
          },
          {
            text: 'AP',
            value: 'AP',
          },
        ],
        onFilter: (value, record) => record.courseType.indexOf(value) === 0,
      },
      {
        title: this.$t('common.schoolYear'),
        dataIndex: 'schoolYearName',
        key: 'schoolYearName',
        filters: this.schoolYearList.map(year => {
          return {
            text: year.text,
            value: year.text,
          }
        }),
        onFilter: (value, record) => record.schoolYearName.indexOf(value) === 0,
        scopedSlots: { customRender: 'commonFlex' },
        // ellipsis: true,
      },
      {
        title: this.$t('graduation.courseStatus'),
        dataIndex: 'status',
        filters: [
          {
            text: this.$t('graduation.complete'),
            value: 'COMPLETE',
          },
          {
            text: this.$t('graduation.ongoing'),
            value: 'ONGOING',
          },
        ],
        onFilter: (value, record) => record.status === value,
        scopedSlots: { customRender: 'status' },
      },
      {
        title: this.$t('common.teacher'),
        dataIndex: 'teacherName',
        key: 'teacherName',
        scopedSlots: { customRender: 'commonFlex' },
        // ellipsis: true,
      },
      {
        title: this.$t('grading.scoreSheet.finalScore'),
        dataIndex: 'finalScore',
        scopedSlots: { customRender: 'finalScore' },
        // width: 50,
      },
      {
        title: this.$t('graduation.level'),
        dataIndex: 'level',
        key: 'level',
        scopedSlots: { customRender: 'level' },
        width: 60,
      },
      {
        title: this.$t('common.credit'),
        dataIndex: 'credit',
        key: 'credit',
        width: 50,
      },
      {
        title: this.$t('graduation.calcType'),
        dataIndex: 'scoringType',
        key: 'scoringType',
        scopedSlots: { customRender: 'calcType' },
        filters: [
          { text: this.$t('graduation.compulsory'), value: 'COMPULSORY' },
          { text: this.$t('graduation.elective'), value: 'ELECTIVE' },
        ],
        onFilter: (value, record) => record.scoringType.indexOf(value) === 0,
        width: 110,
      },
      this.operationAuths.includes('2223') && {
        title: this.$t('common.operations'),
        key: 'operations',
        scopedSlots: { customRender: 'operations' },
      },
    ].filter(item => item)
  }

  private get extraColumns(): any {
    return [
      {
        title: this.$t('graduation.course'),
        dataIndex: 'courseName',
        key: 'courseName',
      },
      {
        title: this.$t('common.subject'),
        dataIndex: 'subjectName',
        key: 'subjectName',
      },
      {
        title: this.$t('common.schoolYear'),
        dataIndex: 'schoolYearName',
        key: 'schoolYearName',
      },
      {
        title: this.$t('graduation.associatedCourse'),
        dataIndex: 'relevancyCourseName',
        key: 'relevancyCourseName',
      },
      {
        title: this.$t('common.score'),
        dataIndex: 'grade',
        key: 'grade',
        // width: 50,
      },
      {
        title: this.$t('common.credit'),
        dataIndex: 'credit',
        key: 'credit',
        // width: 50,
      },
      {
        title: this.$t('graduation.calcType'),
        dataIndex: 'scoringType',
        key: 'scoringType',
        scopedSlots: { customRender: 'calcType' },
        filters: [
          { text: this.$t('graduation.compulsory'), value: 'COMPULSORY' },
          { text: this.$t('graduation.elective'), value: 'ELECTIVE' },
        ],
        onFilter: (value, record) => record.scoringType.indexOf(value) === 0,
        // width: 110,
      },
      {
        title: this.$t('common.remark'),
        dataIndex: 'remark',
        key: 'remark',
        ellipsis: true,
        scopedSlots: { customRender: 'commonFlex' },
      },
      this.operationAuths.includes('2223') && {
        title: this.$t('common.operations'),
        key: 'operations',
        scopedSlots: { customRender: 'operations' },
        width: 100,
      },
    ].filter(item => item)
  }

  @Watch('studentId', { immediate: true })
  private onIdChange(val) {
    if (!val) return
    this.getData()
  }

  private created(): void {
    this.getFilter()
  }

  private getData(): void {
    this.loading = true
    this.schoolCourses = []
    this.extraCourses = []
    GraduationController.getStudentAcademicInfo(this.studentId)
      .then(res => {
        this.schoolCourses = res.data.courseInfoResponses.map(item => {
          return {
            ...item,
            teacherName: item.teachers
              .map(teacher => `${teacher.enName} ${teacher.name}`.trim())
              .join('、'),
          }
        })
        this.extraCourses = res.data.extraCourseResponses
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        this.loading = false
      })
  }

  private getFilter(): void {
    Promise.all([
      DropDownController.getSchoolYearRuleList(true),
      DropDownController.getSubjectBySectionsUnion({ campusTypes: ['1233'] }),
      CourseController.getCourseScheduleByCampus('1233'),
    ]).then(res => {
      this.schoolYearList = res[0].data
        .filter(item => item.extraValue !== '1100')
        .map(item => {
          return {
            text: item.value,
            value: item.key,
          }
        })
      this.subjects = res[1].data.map(item => {
        return {
          text: item.value,
          value: item.value,
        }
      })
      this.alternativeSubjects = res[1].data.map(item => {
        return {
          text: item.value,
          value: item.key,
        }
      })
      this.courses = res[2].data.map(grade => {
        return {
          ...grade,
          subOptions: grade.subOptions.map(course => {
            return {
              ...course,
              subOptions: undefined,
            }
          }),
        }
      })
    })
  }

  private save(add): any {
    // this.changeVisible = true
    // console.log(record)
    ;(this.$refs['form'] as any).validate(valid => {
      if (valid) {
        this.addLoading = true
        const { relevancyCourseId } = this.form
        let request = {
          ...this.form,
          relevancyCourseId: relevancyCourseId.length ? relevancyCourseId[1] : undefined,
          studentId: this.studentId,
        }
        GraduationController.setExtraCourse(request)
          .then(res => {
            this.$message.success(this.$tc('common.saveSuccess'))
          })
          .catch(err => console.log(err))
          .finally(() => {
            this.addLoading = false
            EventBus.$emit('refreshStudentInfo')
            if (!add) {
              this.visible = false
            }
          })
      } else {
        return false
      }
    })
  }

  private showModal(type, record?): void {
    this.visible = true
    if (type === 'add') {
      this.extraType = 'add'
      this.form = {
        courseName: undefined,
        schoolYearId: undefined,
        scoringType: 'COMPULSORY',
        credit: 0,
        grade: undefined,
        relevancyCourseId: [],
        remark: undefined,
      }
    } else {
      this.extraType = 'edit'
      const gradeId = this.courses.find(grade =>
        grade.subOptions.map(course => course.key).includes(record.relevancyCourseId)
      )?.key
      this.form = {
        ...record,
        relevancyCourseId: [gradeId, record.relevancyCourseId],
      }
    }
  }

  private passCourse(record): void {
    GraduationController.setPassed(this.studentId, record.courseId).then(res => {
      this.$message.success(this.$tc('common.saveSuccess'))
      EventBus.$emit('refreshStudentInfo')
    })
  }

  private withdrawPassCourse(record): void {
    GraduationController.cancelManualPass(this.studentId, record.courseId).then(res => {
      this.$message.success(this.$tc('common.saveSuccess'))
      EventBus.$emit('refreshStudentInfo')
    })
  }

  private deleteCourse(record): void {
    GraduationController.deleteExtraCourse(record.extraCourseId).then(res => {
      this.$message.success(this.$tc('common.deleteSuccess'))
      EventBus.$emit('refreshStudentInfo')
    })
  }

  private filterData(inputValue, path) {
    return path.some(option => option.value.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
  }
}
