





















































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { DropDownController, GraduationController } from '@/services/request.service'
import {
  toPage,
  guid,
  judgeVnode,
  clearEmptyArray,
  getSchoolInfo,
  getToken,
  handleBatchDownload,
} from '@/utils/utils'
import debounce from 'lodash/debounce'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import TableTotal from '@/components/TableTotal.vue'
import FlexTooltip from '@/components/FlexTooltip.vue'
import filterGroup from '@/components/filterGroup'
import { createPagination } from '@/constant/constant'
import StandardViewer from './components/StandardViewer.vue'
import ChangeStandardModal from './components/ChangeStandardModal.vue'

@Component({
  components: {
    TableTotal,
    FlexTooltip,
    filterGroup,
    StandardViewer,
    ChangeStandardModal,
  },
})
export default class Graduation extends Vue {
  private houses: Array<any> = []
  private sections: Array<any> = []
  private standardList: Array<any> = []
  private data: Array<any> = []
  private allStudentIds: Array<any> = []
  private loading: boolean = true
  private changeLoading: boolean = false
  private exportLoading: boolean = false
  private selectedRowKeys: Array<any> = []
  private realStudentIds: Array<any> = []
  private pagination: any = createPagination({})
  private filter: any = {
    studentName: undefined,
    studentNum: undefined,
    sectionIds: 0,
    houseGroupId: 0,
    graduationSettingId: undefined,
  }
  private form: any = {
    graduationSettingId: undefined,
  }

  private visible: boolean = false
  private changeVisible: boolean = false
  private graduationSettingId: any = 0

  private get locale(): string {
    return this.$store.state.locale
  }

  private get isChildRoute(): boolean {
    return this.$route.name !== 'graduation'
  }

  private get operationAuths(): any {
    return this.$store.state.operationAuths || []
  }

  @Watch('$route', { immediate: true })
  private onRouteChange(to, from): void {
    if (from && from.meta.refresh === true) {
      this.refresh(false)
    }
  }

  private get columns(): Array<any> {
    return [
      {
        // dataIndex: 'studentName',
        // width: 150,
        key: 'studentName',
        title: this.$t('diary.studentNName'),
        ellipsis: true,
        scopedSlots: { customRender: 'name' },
      },
      {
        dataIndex: 'studentNum',
        key: 'studentNum',
        width: 100,
        title: this.$t('common.studentId'),
      },
      {
        dataIndex: 'sectionName',
        key: 'sectionName',
        width: 70,
        title: this.$t('common.grade'),
        // scopedSlots: { customRender: 'gender' },
      },
      {
        dataIndex: 'houseGroupName',
        key: 'houseGroupName',
        title: this.$t('diary.house'),
        // width: 240 * this.ratio,
        ellipsis: true,
        scopedSlots: { customRender: 'commonFlex' },
      },
      {
        dataIndex: 'advisors',
        key: 'advisors',
        title: this.$t('common.tutor'),
        // width: 240 * this.ratio,
        ellipsis: true,
        scopedSlots: { customRender: 'commonFlex' },
      },
      {
        dataIndex: 'standardName',
        key: 'standardName',
        title: this.$t('graduation.graduationRqm'),
        // width: 240 * this.ratio,
        ellipsis: true,
        scopedSlots: { customRender: 'graduationRqm' },
      },
      {
        key: 'compulsory',
        title: this.$t('graduation.compulsoryCredit'),
        width: 100 * this.ratio,
        ellipsis: true,
        scopedSlots: { customRender: 'compulsory' },
      },
      {
        key: 'elective',
        title: this.$t('graduation.electiveCredit'),
        width: 100 * this.ratio,
        ellipsis: true,
        scopedSlots: { customRender: 'elective' },
      },
      {
        dataIndex: 'graduationSchedule',
        key: 'graduationSchedule',
        title: this.$t('graduation.progress'),
        width: 100 * this.ratio,
        ellipsis: true,
        scopedSlots: { customRender: 'progress' },
      },
      this.operationAuths.includes('2222') && {
        key: 'operations',
        title: this.$t('common.operations'),
        scopedSlots: { customRender: 'operations' },
        width: 150,
      },
    ].filter(item => item)
  }

  private get schoolId(): number {
    return getSchoolInfo().schoolId
  }

  private get ratio(): any {
    return this.$store.state.ratio || 1
  }

  private created(): void {
    this.getFilters()
  }

  private getFilters(): void {
    Promise.all([
      DropDownController.getSectionsByType('1233'),
      DropDownController.getHouseGroupListAll(),
      GraduationController.graduationList(),
    ])
      .then(res => {
        this.sections = res[0].data
        this.houses = res[1].data
        this.standardList = res[2].data
        this.getData()
      })
      .catch(err => {
        console.error(err)
      })
  }

  private fetchData = debounce(() => {
    this.getData()
  }, 500)

  private handleTableChange(pagination): void {
    this.$set(this.pagination, 'current', pagination.current)
    this.$set(this.pagination, 'pageSize', pagination.pageSize)
    this.getData(pagination, false)
  }

  private getData(
    page = { pageSize: this.pagination.defaultPageSize, current: 1 },
    isClear = true
  ): void {
    isClear ? (this.selectedRowKeys = []) : ''
    this.loading = true
    const { studentName, studentNum, sectionIds, houseGroupId, graduationSettingId } = this.filter
    const request = {
      pageCurrent: page.current,
      pageSize: page.pageSize,
      studentName: studentName ? studentName.trim() : undefined,
      studentNum: studentNum ? studentNum.trim() : undefined,
      sectionIds: sectionIds ? sectionIds : undefined,
      houseGroupId: houseGroupId ? houseGroupId : undefined,
      graduationSettingId,
    }
    Promise.all([
      GraduationController.studentAcademics(request),
      GraduationController.studentIds(request),
    ])
      .then(res => {
        this.data = res[0].data.items.map(item => {
          return {
            ...item,
            fullName: `${item.lastName},${item.enName} ${item.firstName} ${item.name}`,
            advisors: item.advisors.map(advisor => advisor.displayName).join('、'),
          }
        })
        this.pagination.total = res[0].data.totalItem
        this.pagination.current = page.current
        this.allStudentIds = res[1].data
        toPage(page, this.data, page => {
          this.pagination.current = page
          this.refresh(false)
        })
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => (this.loading = false))
  }

  private viewDetail(graduationSettingId): void {
    this.visible = true
    this.graduationSettingId = graduationSettingId
  }

  private toDetail(id): void {
    this.$store.commit('setSeniorStudentIds', this.allStudentIds)
    this.$router.push({ name: 'studentInfo', params: { studentId: id } })
  }

  private clearSelection(): void {
    this.selectedRowKeys = []
  }

  private onSelectChange(selectedRowKeys): void {
    this.selectedRowKeys = selectedRowKeys
  }

  private selectAll(): void {
    this.selectedRowKeys = cloneDeep(this.allStudentIds)
  }

  private refresh(isClear): void {
    this.getData(this.pagination, isClear)
  }

  private changeStandard(): any {
    // this.changeVisible = true
    // console.log(record)
    ;(this.$refs['form'] as any).validate(valid => {
      if (valid) {
        this.changeLoading = true
        GraduationController.updateStudentStandard(
          this.realStudentIds,
          this.form.graduationSettingId
        )
          .then(res => {
            this.$message.success(this.$tc('common.saveSuccess'))
            this.changeVisible = false
            this.refresh(true)
          })
          .catch(err => console.log(err))
          .finally(() => (this.changeLoading = false))
      } else {
        return false
      }
    })
  }

  private batchChange(): void {
    if (!this.selectedRowKeys.length) {
      this.$message.error(this.$tc('common.batchSelectError'))
      return
    }
    this.realStudentIds = cloneDeep(this.selectedRowKeys)
    this.changeVisible = true
  }

  private singleChange(record): void {
    this.realStudentIds = [record.studentId]
    this.graduationSettingId = record.graduationSettingId
    this.changeVisible = true
  }

  private exportAcademicProgressReport(): void {
    if (!this.selectedRowKeys.length) {
      this.$message.error(this.$tc('common.batchSelectError'))
      return
    }
    if (this.exportLoading) return
    this.exportLoading = true
    const domain = process.env.VUE_APP_DOMAIN
    const printUrl = process.env.VUE_APP_PDF_PRINT_URL
    let token = getToken()
    let schoolInfo = getSchoolInfo()
    const urls = [] as any
    const students = this.data.filter(item => this.selectedRowKeys.includes(item.studentId))
    students.forEach(student => {
      urls.push({
        url: `${printUrl}api/render/?waitFor=i&url=${domain}exportAcademicProgressReport/${token}/${schoolInfo.schoolId}/${student.studentId}`,
        name: `${student.sectionName}_${student.houseGroupName}_${student.fullName}_${student.studentNum}_Academic Progress Report.pdf`,
      })
    })
    handleBatchDownload(urls, `Academic Progress Report`, () => {
      this.exportLoading = false
    })
  }
}
