









































































































import { createPagination } from '@/constant/constant'
import { i18n } from '@/i18n/i18n'
import { DropDownController, GraduationController } from '@/services/request.service'
import { toPage } from '@/utils/utils'
import FlexTooltip from '@/components/FlexTooltip.vue'
import OperationsGroup from '@/components/OperationsGroup'
import { Component, Vue, Model, Emit, Prop, Watch } from 'vue-property-decorator'
import cloneDeep from 'lodash/cloneDeep'

@Component({
  components: {
    FlexTooltip,
    OperationsGroup,
  },
})
export default class StandardViewer extends Vue {
  @Model('change', { type: Boolean }) private readonly visible!: boolean
  @Prop({ default: () => {} }) public readonly graduationSettingId!: any

  private dataLoading: boolean = false

  private formItemLayout: any = {
    labelCol: { span: 7 },
    wrapperCol: { span: 12 },
  }

  private standardEditor: any = {
    standardName: undefined,
    conditions: [
      {
        courseType: 'COMPULSORY',
        title: undefined,
        remark: undefined,
        credits: undefined,
        subjectIds: [],
        courseGroups: [
          {
            courseDetails: [],
            maxAdmitCredits: undefined,
          },
        ],
      },
      {
        courseType: 'ELECTIVE',
        title: undefined,
        remark: undefined,
        credits: undefined,
        subjectIds: [],
        courseGroups: [
          {
            courseDetails: [],
            maxAdmitCredits: undefined,
          },
        ],
      },
    ],
  }

  private get locale(): any {
    return this.$store.state.locale
  }

  private get compulsory(): Array<any> {
    return this.standardEditor.conditions.filter(item => item.courseType === 'COMPULSORY')
  }

  private get elective(): Array<any> {
    return this.standardEditor.conditions.filter(item => item.courseType === 'ELECTIVE')
  }

  private get columns(): any {
    return [
      {
        // dataIndex: 'standardName',
        title: this.$t('graduation.courseGroup', { index: '' }),
        scopedSlots: { customRender: 'name' },
        width: 200,
        // ellipsis: true,
      },
      {
        dataIndex: 'courseDetails',
        title: this.$t('graduation.course'),
        scopedSlots: { customRender: 'course' },
        // ellipsis: true,
      },
      {
        dataIndex: 'maxAdmitCredits',
        title: this.$t('graduation.maxRecognizedCreitds'),
        width: 100,
      },
    ]
  }

  @Watch('visible', { immediate: true })
  private onVisibleChange(val): void {
    if (val) {
      this.getDetail()
    }
  }

  private getDetail(): void {
    GraduationController.graduationDetail(this.graduationSettingId)
      .then(res => {
        this.standardEditor = {
          ...res.data,
          totalCredits: res.data.totalCredits || 0,
          conditions: res.data.conditions.map(condition => {
            return {
              ...condition,
              subjects: condition.subjects.map(subject => subject.subjectName).join('、'),
            }
          }),
        }
      })
      .catch(err => console.log(err))
      .finally(() => (this.dataLoading = false))
  }

  @Emit('change')
  private closeModal(): boolean {
    return false
  }
}
