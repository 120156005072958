































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'

import { StudentController, GraduationController } from '@/services/request.service'
import { getSchoolInfo } from '@/utils/utils'

import moment from 'moment'
import ChangeStandardModal from './components/ChangeStandardModal.vue'
import StandardViewer from './components/StandardViewer.vue'
import GraduationMenu from './components/GraduationMenu.vue'
import EventBus from '@/utils/EventBus'
import { getToken } from '@/utils/utils'
import { saveAs } from 'file-saver'

Component.registerHooks(['beforeRouteLeave'])

@Component({
  components: {
    ChangeStandardModal,
    StandardViewer,
    GraduationMenu,
  },
})
export default class StudentInfo extends Vue {
  private standardDetailVisible = false
  private changeVisible = false
  private saveLoading = false
  private canLeave = true
  private info: any = {
    myStudent: false,
  }
  private graduationInfo: any = {
    graduationSchedule: 0,
  }
  private schoolId: any = (getSchoolInfo() || {}).schoolId
  private moment = moment
  private exportLoading = false

  private get graduationMenu(): any {
    return this.$refs.graduationMenu || null
  }

  private get studentId(): number {
    return parseInt(this.$route.params.studentId, 10)
  }

  private get studentIds(): any {
    return this.$store.state.seniorStudentIds
  }

  private get operationAuths(): any {
    return this.$store.state.operationAuths || []
  }

  private calcBg(status, isBorder): string {
    if (status === '1016') {
      return `rgba(38, 184, 137, ${isBorder ? 1 : 0.14})`
    } else {
      return `rgba(250, 173, 20, ${isBorder ? 1 : 0.14})`
    }
  }

  private getStudentBrief(): void {
    StudentController.getStudentBriefInfo(this.studentId)
      .then(res => {
        this.info = res.data
        this.$store.commit('setCurriculumStudentInfo', this.info)
      })
      .catch(err => {
        console.error(err)
      })
  }

  private getGraduationInfo(): void {
    GraduationController.studentStandard(this.studentId)
      .then(res => {
        this.graduationInfo = res.data
      })
      .catch(err => {
        console.error(err)
      })
  }

  private preStudent(): void {
    if (this.studentIds.length <= 1) return
    const index = this.studentIds.findIndex(item => item === this.studentId)
    if (index === -1) {
      console.log('学号错误')
      return
    }
    if (!this.canLeave) {
      this.$confirm({
        title: this.$t('common.unsaveConfirm') as string,
        onOk: () => {
          this.$router.push({
            params: {
              studentId: this.studentIds[index - 1 === -1 ? this.studentIds.length - 1 : index - 1],
            },
          })
          this.canLeave = true
        },
      })
    } else {
      this.$router.push({
        params: {
          studentId: this.studentIds[index - 1 === -1 ? this.studentIds.length - 1 : index - 1],
        },
      })
    }
  }

  private nextStudent(): void {
    if (this.studentIds.length <= 1) return
    const index = this.studentIds.findIndex(item => item === this.studentId)
    if (index === -1) {
      console.log('学号错误')
      return
    }
    if (!this.canLeave) {
      this.$confirm({
        title: this.$t('common.unsaveConfirm') as string,
        onOk: () => {
          this.$router.push({
            params: {
              studentId: this.studentIds[index + 1 === this.studentIds.length ? 0 : index + 1],
            },
          })
          this.canLeave = true
        },
      })
    } else {
      this.$router.push({
        params: {
          studentId: this.studentIds[index + 1 === this.studentIds.length ? 0 : index + 1],
        },
      })
    }
  }

  @Watch('studentId', { immediate: true })
  private onIdChange(val) {
    if (!val) return
    this.getStudentBrief()
    this.getGraduationInfo()
  }

  private mounted(): void {
    EventBus.$on('refreshStudentInfo', this.reload)
    this.$once('hook:beforeDestroy', () => {
      EventBus.$off('refreshStudentInfo', this.reload)
    })
  }

  private viewStandardDetail(): void {
    this.standardDetailVisible = true
  }

  private changeStandard(): void {
    this.changeVisible = true
  }

  private reload(): void {
    this.getGraduationInfo()
    this.graduationMenu?.refreshData()
  }

  private exportAcademicProgressReport(): void {
    if (this.exportLoading) return
    this.exportLoading = true
    setTimeout(() => {
      this.exportLoading = false
    }, 3000)
    setTimeout(() => {
      const domain = process.env.VUE_APP_DOMAIN
      const printUrl = process.env.VUE_APP_PDF_PRINT_URL
      let token = getToken()
      let schoolInfo = getSchoolInfo()
      const student = this.info
      const { studentId } = this
      saveAs(
        `${printUrl}api/render/?waitFor=i&url=${domain}exportAcademicProgressReport/${token}/${schoolInfo.schoolId}/${studentId}`,
        `${student.sectionName}_${student.house}_${student.lastName},${student.enName} ${student.firstName} ${student.name}_${student.studentNum}_Academic Progress Report.pdf`
      )
    }, 1000)
  }
}
